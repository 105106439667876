import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Calendar as ReactCalendar } from 'react-calendar-component';
import moment from 'moment-timezone';

import momentFr from '../../config/moment.fr';
import { colors, mobileThresholdPixels } from '../Home/v3/styledComponents';
import './Calendar.css';
import { getActiveSlotDates, dateFormat } from '../../services/slotsFormatting';
import arrowLeft from '../../assets/arrow.left.grey.png';
import arrowRight from '../../assets/arrow.right.grey.png';

moment.updateLocale('fr', momentFr);
moment.tz.setDefault('Europe/Paris');
const capitalizeFirstLetter = text => text.substr(0, 1).toUpperCase() + text.substr(1);
const weekdays = ['Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam', 'Dim'];

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 345px;
  height: auto;
  background-color: ${colors.navy};
  color: ${colors.white};
  box-shadow: 0px 1px 6px ${colors.blueGrey};
  border-radius: 3px;
  @media (max-width: ${mobileThresholdPixels}) {
    width: 277px;
  }
`;

const Day = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 37px;
  height: 23px;
  margin: 2px 1.5px;
  border-radius: 3px;
  font-family: Roboto;
  line-height: 10px;
  font-size: 12px;
  cursor: pointer;
  ${props => !props.isAvailable && `color: ${colors.darkBlueGrey}; pointer-events: none;`}
  ${props => props.isSelected && `color: ${colors.navy}; background-color: ${colors.white};`}
  ${props => props.isActive && `background-color: ${colors.darkBlueGrey};`}
  @media (max-width: ${mobileThresholdPixels}) {
    width: 30.58px;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 29px 32px 19px;
  font-weight: bold;
  line-height: 22px;
  font-size: 16px;
`;

const HeaderSubContainer = styled.div`
  display: flex;
  ${props => props.justifyContent && 'justify-content: space-between;'}
  width: 100%;
  ${props => props.margins && 'margin-top: 22px; width: 285px;'}
  align-items: center;
  @media (max-width: ${mobileThresholdPixels}) {
    ${props => props.margins && 'margin-top: 20px; width: 240px;'}
  }
`;

const Weekday = styled(Day)`
  color: ${colors.darkBlueGrey};
  font-weight: normal;
`;

const Line = styled.div`
  position: relative;
  flex: 1;
  height: 0.75px;
  width: 262px;
  background-color:  ${colors.darkBlueGrey};
  @media (max-width: ${mobileThresholdPixels}) {
    width: 230px;
  }
`;

const StyledImg = styled.img`
  cursor: pointer;
`;


class Calendar extends Component {
  constructor() {
    super();
    this.state = {
      displayedDate: moment(),
    };
  }
  render() {
    const { displayedDate } = this.state;
    const { selectDate, selectedDate, selectedDates } = this.props;
    const activeSlotDates = getActiveSlotDates(selectedDates);
    return (
      <MainContainer>
        <ReactCalendar
          onChangeMonth={date => this.setState({ displayedDate: date })}
          date={displayedDate}
          onPickDate={date => selectDate(date)}
          renderDay={({ day, _, onPickDate }) => {
            if (!day.isSame(displayedDate, 'month')) {
              return (
                <Day key={day.format()} isAvailable={false}>
                  {(displayedDate.isSame(day, 'month') && day.format('D'))}
                </Day>);
            }
            return (
              <Day
                key={day.format()}
                isAvailable={day
                  .isSameOrAfter(Math.max(13, day.hours() + 4) > 21 ? moment().add(1, 'day') : moment(), 'day')
                }
                onClick={() => onPickDate(day)}
                isSelected={day.isSame(selectedDate, 'day')}
                isActive={
                  activeSlotDates.includes(day.format(dateFormat)) &&
                  !day.isSame(selectedDate, 'day') &&
                  day.isSameOrAfter(moment(), 'day')
                }
              >
                {day.format('D')}
              </Day>
            );
          }}
          renderHeader={({ date, onPrevMonth, onNextMonth }) => (
            <HeaderContainer>
              <HeaderSubContainer justifyContent>
                <StyledImg onClick={onPrevMonth} src={arrowLeft} alt="Mois précédent" />
                <div className="Calendar-header-currentDate">
                  {capitalizeFirstLetter(date.format('MMMM YYYY'))}
                </div>
                <StyledImg onClick={onNextMonth} src={arrowRight} alt="Mois suivant" />
              </HeaderSubContainer>
              <HeaderSubContainer margins>
                {weekdays.map(weekday => <Weekday key={weekday}>{weekday}</Weekday>)}
              </HeaderSubContainer>
              <Line />
            </HeaderContainer>
          )}
        />
      </MainContainer>
    );
  }
}

Calendar.propTypes = {
  selectDate: PropTypes.func.isRequired,
  selectedDate: PropTypes.shape({}).isRequired,
  selectedDates: PropTypes.shape({}).isRequired,
};

export default Calendar;
