import React from 'react';

import Layout from '../../layouts/index';
import RdvContainer from './containers/RdvContainer';
import MenuBar from '../../components/Home/MenuBar';

const Step2 = () => (
  <Layout routeSlug="Step2">
    <MenuBar />
    <RdvContainer />
  </Layout>
);

export default Step2;
